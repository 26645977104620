import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

if(typeof document === "undefined"){
	// var jsdom = require("jsdom"); 
	// var $ = require("jquery")(jsdom.jsdom().createWindow()); 
} else {
	var $ = require("jquery")
}

const Question = ({next, question, type, options }) => (
	<div>
        <h3 dangerouslySetInnerHTML={{__html: question}}  />
        { getForm(type, options, next) }
    </div>
)

const getForm = (type, options = [], next = () => {}) => {
    switch (type) {
        case "question":
            return (
                <div className="input-container">
                    <input className="effect-3" type="text" placeholder="Tapez votre réponse ici" />
                    <span className="focus-border"></span>
                </div>
            )
        case "question-text":
            return (
                <div>
                    <div className="input-container">
                        <textarea className="text-area" placeholder="Tapez votre réponse ici" />
                        <span className="focus-border"></span>
                    </div>
                    <hr />
                    <small>* Alt pour faire un saut de ligne</small>
                </div>
            )
        case "options":
            return options.map( (el, index) => 
                (
                    <React.Fragment key={index}>
                        <input 
                            type="radio" 
                            id={`radio${index}`} 
                            className="radio-button" 
                            name="needs"
                            onChange={e => next()}
                            value={index}
                        />
                        <label htmlFor={`radio${index}`} className="needs-options">
                            <li> 
                                <svg className="SVGInline-svg" width="16" height="13" xmlns="http://www.w3.org/2000/svg"><path fillRule="nonzero" d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"></path></svg>
                                { el }
                            </li>
                        </label>
                    </React.Fragment>
                )
            )
        }
}

if(typeof document !== "undefined"){
    $(document).on("keydown", "textarea", function(e){
        if(e.key == "Alt") $(this).val($(this).val() + "\n")
        return true
    })
}

Question.propTypes = {
    question: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.array,
    next: PropTypes.func
  }
  
Question.defaultProps = {
    question: ``,
    type: ``,
    options: ``,
    next: () => {}
}

export default Question
