import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
import { Link } from "gatsby"
import SEO from "../components/seo"
import Question from "../components/question"
import helpers from "../scripts/helpers"
import database from "../scripts/database"
import cssStyle from "../styles/modern-contact.css"
import Header from "../components/header"

if(typeof document === "undefined"){
    // var jsdom = require("jsdom"); 
    // var $ = require("jquery")(jsdom.jsdom().createWindow()); 
} else {
    var $ = require("jquery")
}

class ModernContact extends React.Component {
  render = () => {
    setTimeout(() => {
        if(typeof document !== "undefined")
            this.checkQuestionVisibility()
    }, 0);
    return (
        <>
            <SEO title="Nous Contacter" />
            <Header />
            <div style={{
                marginTop: "5rem"
            }}>
                { this.askMeSteps() }
            </div>
        </>
    )
}

  next = (value) => {
    this.setState({
        step: value
    })
  }

  askMeSteps = () => {
    switch (this.state.step) {
        case "welcome":
            return (
                <div className="main-section">
                    <div className="text-center welcome">
                        <img src="/img/contact/hello.png" />
                        <h1> Vous avez des questions? Des commentaires?  </h1>
                        <br /><br />
                        <div onClick={ e => this.next("questions") } className='pulse-button'>Bien sûr</div>
                    </div>
                </div>
            )
        case "questions":
            return (
                <div className="main-section">
                    <div className="text-center welcome">
                        <div 
                            id={`question-0`} 
                            className="question" 
                        />
                        {
                            this.state.questions.map((question, index) => 
                                (
                                    <div id={`question-${index+1}`} className="question" onKeyDown={this.handleKeyPress}>
                                        <Question 
                                            key={index}
                                            question={question.question}
                                            type={question.type}
                                            options={question.options}
                                            next={e => this.nextQuestion()}
                                        />
                                        <br />
                                        <a href="#" onClick={e => this.nextQuestion()} class="btn blue">OK</a>
                                    </div>
                                )
                            )
                        }
                        <div 
                            id={`question-${this.state.questions.length+1}`} 
                            className="question"
                        />
                    </div>
                </div>
            )
        
            case "finish": 
                return (
                    <div className="main-section">
                        {
                            this.state.submited ? (
                                <React.Fragment>
                                    <h2 className="succeeded"> Merci Pour Votre Message ({this.state.timer}:00)</h2>
                                </React.Fragment>
                            ):(
                                <button onClick={this.submit} className="shinny"> Envoyer </button>
                            )
                        }
                    </div>
                )
                
        default:
            break;
    }
  }

    constructor(props){
        super(props)
        
        let questions = database.contact.questions

        this.state = {
            step: "welcome",
            questions: questions,
            mainQuestion: 1,
            submited: false,
        }

    }

  submit = () => {
        this.setState({
            submited: true
        })
  }

  handleKeyPress = (target) => {
    if(target.key=="Enter" ||  target.key=="Tab"){
      this.nextQuestion()   
    } 
  }

  nextQuestion = () => {
    let {mainQuestion, questions} = this.state
    if (mainQuestion != questions.length)
        this.setState({
            mainQuestion: mainQuestion + 1
        })
    else {
        this.setState({
            step: "finish",
            submited: true,
            timer: 5
        })
        setInterval(() => {
            let {timer} = this.state
            this.setState({
                timer: timer - 1
            })
        }, 1000);
        setTimeout(() => {
            window.location.href = "/"
        }, 5000);
    }
    this.handleInputFocuse()
  }

  previousQuestion = () => {
    let {mainQuestion} = this.state
    if (mainQuestion > 0 )
        this.setState({
            mainQuestion: mainQuestion - 1
        })
        this.handleInputFocuse()
  }

  handleInputFocuse = () => {
    setTimeout(function() { 
        $(".question.active input").focus()
    }, 100);
  }

  checkQuestionVisibility = () => {
    let {mainQuestion} = this.state
    let $allQuestions = $(".question")
    let $mainQuestion = $(`#question-${mainQuestion}`)
    let $previousQuestion = $(`#question-${mainQuestion-1}`)
    let $nextQuestion = $(`#question-${mainQuestion+1}`)
    $(document).ready(function(){
        $allQuestions.removeClass("active").hide()
        $mainQuestion.show().addClass("active")
        $previousQuestion.show()
        $nextQuestion.show()
        // })
    })
  }

  componentDidMount = () => {
    helpers.loadCSS(cssStyle, "#modern-contact")
  }

  componentWillUnmount = () => {
    helpers.unloadCSS("#modern-contact")
  }

}

export default ModernContact
