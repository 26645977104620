export default {
    "contact": {
        "questions": [
            {
                "question": `
                    Quel est votre nom? *
                `,
                "type": "question",
                "options": []
            },{
                "question": `
                    Quelle est votre meilleure adresse e-mail, _____? *
                    <small>Nous allons l'utiliser pour vous répondre. Pas de spam ou de newsletters inattendues.</small>
                `,
                "type": "question",
                "options": []
            },{
                "question": `
                    Quelle est la raison pour laquelle vous nous contactez?
                    <small>Pensez à cela comme à un champ d'objet dans un courrier électronique. Mais déjà rempli pour vous.</small>
                `,
                "type": "options",
                "options": [
                    "J'aimerais travailler avec vous",
                    "J'ai des feedback",
                    "Je cherche des conseils",
                    "Je voudrais juste discuter"
                ]
            },{
                "question": `
                    Quel est votre message?
                `,
                "type": "question-text",
                "options": []
            },
        ]
    }
}